.content ol {
  list-style: none;
}

.content ol:first-of-type {
  counter-reset: step-counter;
}

.content li {
  margin-left: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
  position: relative;
}

.content ol li::before {
  counter-increment: step-counter;
  content: counter(step-counter);
  border: 1px solid orange;
  background-color: orange;
  border-radius: 1000px;
  display: block;
  position: absolute;
  left: -2em;
  top: -0.1em;
  width: 1.5em;
  height: 1.5em;
  font-weight: 900;
  text-align: center;
  color: black;
}