.height600 {
  height: 600px;
}

.rbc-event-content {
  font-family: 'Arial Narrow';
}

.dnc__blog-course_groupB p {
}

.content>ol {
  list-style: none;
}

.content>ol:first-of-type {
  counter-reset: step-counter;
}

.content>ol>li, .content>ul>li {
  margin-left: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
  position: relative;
}

.content>ol>li::before, .content>ul>li::before {
  border: 1px solid orange;
  background-color: orange;
  border-radius: 1000px;
  display: block;
  position: absolute;
  left: -2em;
  top: -0.1em;
  width: 1.5em;
  height: 1.5em;
  font-weight: 900;
  text-align: center;
  color: black;
}

.content>ol>li::before {
  counter-increment: step-counter;
  content: counter(step-counter);
}

.content>ul>li::before {
  counter-increment: step-counter;
  content: " ";
}

.content>ol>li>a, .content>ul>li>a {
  font-weight: bold;
  font-size: larger;
  line-height: 0;
}

.content p>a {
  color: orange;
}

.content a:hover {
  text-decoration: underline;
}

.content .resource-title {
  color: var(--color-text);
  font-style: italic;
}

.content img {
  max-width: 100%;
}

.content p.image-container {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.course-card-content h4 {
  color: #ddd;
}
.course-card-content p {
  color: #bbb;
  padding: 0;
}

.dnc__blog-course_groupB {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1550px) {
  .dnc__blog-course_groupB {
    grid-template-columns: repeat(4, 1fr);
  } 
}

@media screen and (max-width: 1270px) {
  .dnc__blog-course_groupB {
    grid-template-columns: repeat(3, 1fr);
  } 
}

@media screen and (max-width: 1000px) {
  .dnc__blog-course_groupB {
    grid-template-columns: repeat(2, 1fr);
  } 
}

@media screen and (max-width: 660px) {
  .dnc__blog-course_groupB {
    grid-template-columns: repeat(1, 1fr);
  } 
}
