* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg)
}

div.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

a {
  color: unset;
  text-decoration: none;
}

.header__bg {
  background-size: cover;
  background-position-x: 100%;
  background-color: whitesmoke;
}
@media screen and (max-width: 1300px) {
  .header__bg {
    background-position-x: 88%;
  }
}
@media screen and (max-width: 1080px) {
  .header__bg {
    background-position-x: 50%;
  }
}

/* https://angrytools.com/gradient/ */
.gradient__bg {
  /* global 92%+ browsers support */
  background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
}

/* https://angrytools.com/gradient/ */
.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clop: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem var(--site-margin-right) 4rem var(--site-margin-left);
}

.section__margin {
  margin: 4rem var(--site-margin-right) 4rem var(--site-margin-left);
}

/* https://animista.net/play/basic/scale-up */
.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/* https://animista.net/play/basic/scale-up/scale-up-tr */
.scale-up-tr {
	-webkit-animation: scale-up-tr 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-tr 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  } 

  .section__margin {
    margin: 4rem;
  } 
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  } 
  
  .section__margin {
    margin: 4rem 2rem;
  } 
}

.side-divider {
  display: flex;
  flex-direction: row;
}

.side-divider .screenshots {
  flex-shrink: 5;
  align-self: flex-start;
  padding-left: 5%;
}

.side-divider .screenshots p {
  text-align: right;
}
