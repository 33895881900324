.form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.form input.item, .form textarea.item, .form .buttonbox, .form .center {
  flex-grow: 1;
}

.form .buttonbox {
  display: flex;
  justify-content: flex-end;
}

.form .center {
  display: flex;
  justify-content: center;
}

.form button.item {
  width: 25%;
  min-width: 100px;
}

.form textarea {
  height: auto;
}

.form .break {
  flex-basis: 100%;
  height: 0;
}

.form .item {
  margin: 5px;
}

.form p {
  text-align: left;
}

