.dnc__blog-container_book {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dnc__blog-container_book-image {
  max-width: 100%;
  max-height: 100%;
}

.dnc__blog-container_book-image img {
  max-width: 100%;
  max-height: 100%;
  box-shadow: 10px 10px 20px grey;
}

.dnc__blog-container_book-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  height: 100%;
  color: black;
}

.dnc__blog-container_book-content>div>h4 {
  color: black;
}

.dnc__blog-container_book-content>div>h3 {
  font-weight: 800;
  font-size: 25.11px;
  line-height: 30px;
  padding-bottom: 5px;
}

.dnc__blog-container_book-author {
  font-weight: bold;
  font-size: 11.649px;
}

.dnc__blog-container_book-date {
  font-weight: bold;
  font-size: 11.649px;
}
