.dnc__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position-x: -20px;
  user-select: none;
}

.dnc__langbar {
  position: absolute;
  left: var(--site-margin-left);
  top: 110px;
  display: flex;
  user-select: none;
  border-radius: 5px;
  box-shadow: 0px 2px 5px black;
}

.dnc__langbar a {
  background-color: var(--color-nav-bubble-bg);
  color: black;
  padding: 0 5px;
  box-shadow: inset 0px 0px 0px 1px rgba(0,0,0,0.1);
}
.dnc__langbar a:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.dnc__langbar a:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dnc__langbar a.selected {
  background-color: orange;
  z-index: 1;
  box-shadow: 0px 2px 5px black;
}

.dnc__navbar-logo {
  padding-left: var(--site-margin-left);

  margin-right: 2rem;
  display: inline;
  color: #000;
  text-shadow: #fff 0 0 10px;
  font-family: var(--font-family);
  font-weight: 900;
  font-size: 24px;
  line-height: 15px;
  text-transform: capitalize;
}

.dnc__navbar-logo a {
  cursor: pointer;
}

.dnc__navbar-link {
  padding: 10px;
}

.in-route {
  background-color: orange;
  border-radius: 50px;
}

.dnc__navbar-links_container {
  padding-right: 6rem;

  padding-left: 2rem;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  background-color: var(--color-nav-bubble-bg);
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 5px 10px rgba(0,0,0,0.5);
}

.dnc__navbar-links {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dnc__navbar-sign {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dnc__navbar-links_container p,
.dnc__navbar-sign p,
.dnc__navbar-menu_container p {
  color: var(--color-nav-bubble-txt);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;

  margin: 14px calc(1rem - 10px);
  cursor: pointer;
}

.dnc__navbar-sign button,
.dnc__navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #ff4820;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border-radius: 5px;
  border: 0;
  outline: none;
  cursor: pointer;
}

.dnc__navbar-menu {
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: none;
  position: relative;
}

.dnc__navbar-menu svg {
  cursor: pointer;
}

.dnc__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  z-index: 10;
  text-align: end;
  background: var(--color-nav-menu-bg);
  padding: 2rem;
  position: absolute;
  top: 30px;
  right: -3rem;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: black 0 5px 10px;
}

.dnc__navbar-menu_container p {
  margin: 1rem 0;
}

.dnc__navbar-menu_container-links-sign {
  display: none;
}

@media screen and (max-width: 1080px) {
  .dnc__navbar-links {
    display: none;
  }
  .dnc__navbar-menu {
    display: flex;
  }
  .dnc__langbar {
    top: 97px;
  }
}

@media screen and (max-width: 800px) {
  .dnc__navbar {
    background-position-x: -40px;
  }
  .dnc__navbar-links_container {
    padding-right: 4rem;
  }
}

@media screen and (max-width: 620px) {
  .dnc__navbar {
    background-position-x: -55px;
  }
  .dnc__navbar-links_container {
    padding-right: 4rem;
  }
  .dnc__navbar-menu_container {
    top: 20px;
  }
  .dnc__navbar-sign {
    display: none;
  }
  .dnc__navbar-menu_container-links-sign {
    display: block;
  }
}
