.dnc__blog-container_card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--color-footer);
  box-shadow: 10px 10px 20px black;
}

.dnc__blog-container_card-image {
  width: 100%;
  height: 100%;
  background: var(--color-footer);
}

.dnc__blog-container_card-image img {
  width: 100%;
  height: 100%;
}

.dnc__blog-container_card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  height: 100%;
}

.dnc__blog-container_card-content>div>p {
  font-weight: bold;
  font-size: 11.649px;
  line-height: 35px;
  color: white;
}

.dnc__blog-container_card-content>div>h3 {
  font-weight: 800;
  font-size: 25.11px;
  line-height: 30px;
  color: white;
  padding-bottom: 5px;
}

.dnc__blog-container_card-content>div>p:last-child {
  margin-top: 3rem;
}

