@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #FAF8F5;
  --color-footer : #031B34;
  --color-footer-text : #789;
  --color-blog: #042c54;
  --color-text: #345;
  --color-subtext: #FF8A71;
  --color-nav-bubble-bg: #987;
  --color-nav-menu-bg: #876;
  --color-nav-bubble-txt: #321;

  --site-margin-left: 6rem;
  --site-margin-right: 6rem;

  --page-margin-top: 2rem;
  --page-margin-bottom: 6rem;

  color: var(--color-text);
  font-family: var(--font-family);
  scrollbar-gutter: stable;
  overflow-y: scroll;
}

.content h1, .content h2 {
  line-height: 100%;
  padding: 1rem 0;
}

h4 {
  color: var(--color-footer-text);
}

@media screen and (max-width: 800px) {
  :root {
    --site-margin-left: 4rem;
    --site-margin-right: 4rem;
  }
}

@media screen and (max-width: 620px) {
  :root {
    --site-margin-left: 2rem;
    --site-margin-right: 2rem;
  }
}
