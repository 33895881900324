.dnc__blog {
  display: flex;
  flex-direction: column;
}

.dnc__blog-heading {
  width: 100%;
  text-align: left;
  margin-bottom: 5rem;  
}

.dnc__blog-heading-h1 {
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
}

.dnc__blog-container {
  display: flex;
  flex-direction: row;
}

.dnc__blog-container_groupA {
  flex: 0.75;
  margin-right: 2rem;
}

.dnc__blog-container_groupB {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

@media screen and (max-width: 1080px) {
  .dnc__blog-container {
    flex-direction: column;
  }
  .dnc__blog-container_groupA {
    flex: 0.75;
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 620px) {
  .dnc__blog-container_groupB {
    grid-template-columns: repeat(1, 1fr);
  }
  .dnc__blog-course_groupB {
    grid-template-columns: repeat(1, 1fr);
  }
}