.dnc__id {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0;
}

.id-box-far-outer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.id-box-outer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0;
}

.id-detail-group {
  display: flex;
  flex-direction: column;
}

.id-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
  align-items: center;
  margin-bottom: 5px;
}

.id-box-title {
  flex: none;
  padding: 0 5px;
  color: gray;
}

.dnc__id > div {
  flex: 1;
}

.id-box-border {
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  padding: 2px 5px;
}

.id-box-border > div {
  flex: 1;
}

.id-fine-print {
  font-size: 8pt;
}